import React, { useState } from "react"
import { color_menu } from "_src/assets/data/global_colors"
// import { Logger } from "_data/global_functions.js"
import { motion, AnimatePresence } from "framer-motion"
import CloseButton from "./closeButton"
import Hamburger from "./burga"
import Menu from "_components//nav/footer/_footer"
import styled from "styled-components"

const circle_variant = {
  open: {
    scale: 100,
    transition: {
      duration: 0.75,
    },
  },
  closed: {
    scale: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
}
const burger_variant = {
  hide: {
    scale: 0.75,
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
  show: {
    scale: 1,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 20,
      mass: 2,
      restDelta: 0.001,
      restSpeed: 0.0001,
      delay: 0.45,
    },
  },
}
const close_variant = {
  hide: {
    scale: 0,
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
  show: {
    scale: 1,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30,
      mass: 2,
      restDelta: 0.001,
      restSpeed: 0.0001,
    },
  },
}

const MenuBurger = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <div>
      <AnimatePresence>
        {menuOpen && (
          <Nav exit={{ opacity: 0 }}>
            <Menu isHamburger="true" />
          </Nav>
        )}
      </AnimatePresence>

      <div
        style={{
          position: "relative",
          height: 40,
          width: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Circle
          initial={{ scale: 0 }}
          variants={circle_variant}
          animate={menuOpen ? "open" : "closed"}
        />
        <HamburgerWrapper
          initial={{ opacity: 1, scale: 1 }}
          variants={burger_variant}
          animate={menuOpen ? "hide" : "show"}
        >
          <Hamburger setMenuOpen={setMenuOpen} />
        </HamburgerWrapper>
        <CloseButtonWrapper
          initial={{ opacity: 0, scale: 0 }}
          variants={close_variant}
          animate={menuOpen ? "show" : "hide"}
        >
          <CloseButton setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
        </CloseButtonWrapper>
      </div>
    </div>
  )
}

const HamburgerWrapper = styled(motion.div)`
  position: absolute;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CloseButtonWrapper = styled(motion.div)`
  position: fixed;
  height: 40px;
  width: 40px;
  z-index: 3000;
`

const Circle = styled(motion.div)`
  position: fixed;
  z-index: 2000;
  background-color: ${color_menu};
  width: 40px;
  height: 40px;
  border-radius: 100%;
`

const Nav = styled(motion.div)`
  position: fixed;
  z-index: 2005;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default MenuBurger
