import React, { useState, useEffect } from "react"
import Logo from "_components/misc/logo"
import { motion } from "framer-motion"
import FullMenu from "./menu"
import Hamburger from "./hamburger"
import { navigate } from "gatsby"
import styled from "styled-components"

const Header = (props) => {
  const [isApp, setIsApp] = useState(false)

  function NavigateHome() {
    if (!isApp) navigate("/")
  }

  useEffect(() => {
    if (props.app === "true") setIsApp(true)
  }, [props.app])

  return (
    <>
      {isApp && (
        <AppWrapper>
          <Logo multiplier=".8" />
        </AppWrapper>
      )}

      {!isApp && (
        <Wrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          <LogoContainer
            style={{ cursor: "pointer", zIndex: 2000 }}
            onClick={NavigateHome}
          >
            <Logo multiplier=".925" />
          </LogoContainer>
          <HamburgerWrapper onClick={props.HamburgerClicked}>
            <Hamburger />
          </HamburgerWrapper>
          <MenuWrapper>
            <FullMenu />
          </MenuWrapper>
        </Wrapper>
      )}
    </>
  )
}

const LogoContainer = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`

const AppWrapper = styled.div`
  position: absolute;
  left: 15px;
`

const Wrapper = styled(motion.div)`
  position: absolute;
  width: 90vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HamburgerWrapper = styled.div`
  @media only screen and (min-width: 1050px) {
    display: none;
  }
`

const MenuWrapper = styled.div`
  @media only screen and (max-width: 1049px) {
    display: none;
  }
`

export default Header
