import React from "react"
import styled from "styled-components"
import { between } from "polished"
import { color_pageTitle } from "_src/assets/data/global_colors"

function PageHeadline(props) {
  return <Headline style={{ color: color_pageTitle }}>{props.copy}</Headline>
}

const Headline = styled.h1`
  font-family: montserrat;
  font-size: ${between("32px", "39px", "800px", "2560px")};
  font-weight: 800;
  line-height: 1.15em;
  margin-bottom: 10px;
  user-select: none;
  @media only screen and (max-width: 400px) {
    font-size: 1.9em;
    line-height: 1.1em;
  }
`

export default PageHeadline
