import React from "react"
import styled from "styled-components"
import { SiYoutube } from "react-icons/si"
import { SiTwitter } from "react-icons/si"
import { motion } from "framer-motion"

const Socials = () => {
  const spring = {
    type: "spring",
    damping: 100,
    stiffness: 200,
  }

  return (
    <SocialContainer>
      <a
        href="https://www.youtube.com/channel/UCcbAmJDgC_GxU0Pombs1iNA"
        rel="noopener noreferrer"
        target="_blank"
      >
        <YouTubeContainer
          transition={spring}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.7, buttonStatus: 1 }}
        >
          <SiYoutube />
        </YouTubeContainer>
      </a>

      <a
        href="https://twitter.com/activeemdr"
        rel="noopener noreferrer"
        target="_blank"
      >
        <TwitterContainer
          transition={spring}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.7, buttonStatus: 1 }}
        >
        <SiTwitter />
        </TwitterContainer>
      </a>
    </SocialContainer>
  )
}

const SocialContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`
const YouTubeContainer = styled(motion.div)`
  font-size: 1.8em;
  color: #e4e4e4;
  cursor: pointer;
`
const TwitterContainer = styled(motion.div)`
  font-size: 1.6em;
  margin-left: 15px;
  color: #e4e4e4;
  cursor: pointer;
`
export default Socials
