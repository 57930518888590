import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import {
  color_logoColor,
  color_logoBlock,
} from "_src/assets/data/global_colors"

const spring = {
  type: "spring",
  stiffness: 530,
  damping: 60,
  mass: 10,
  restDelta: 0.001,
  restSpeed: 0.001,
}

const Logo = (props) => {
  var multiplier = props.multiplier
  if (multiplier == null) multiplier = 1

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null
  }

  return (
    
      <motion.div
        style={{
          position: "relative",
          perspective: "500px",
          transform: `scale(${props.multiplier})`,
        }}
      >
        <motion.div
          style={{
            perspective: "500px",
          }}
          initial={{ scale: 1.01 }}
          whileHover={{ scale: 1.04 }}
          whileTap={{ scale: 0.98 }}
          transition={spring}
        >
          <Active
            style={{
              color: color_logoColor,
              textShadow: `-2px 2px rgba(${hexToRgb(color_logoBlock).r}, ${
                hexToRgb(color_logoBlock).g
              }, ${hexToRgb(color_logoBlock).b}, .3)`,
            }}
            size={props.size}
          >
            active
          </Active>
          <Emdr
            style={{
              backgroundColor: color_logoBlock,
              boxShadow: `2px -2px rgba(${hexToRgb(color_logoBlock).r}, ${
                hexToRgb(color_logoBlock).g
              }, ${hexToRgb(color_logoBlock).b}, .3)`,
            }}
            shadowColor={color_logoBlock}
          >
            EMDR
          </Emdr>
        </motion.div>
      </motion.div>
 
  )
}

const Active = styled.div`
  display: inline-block;
  position: relative;
  font-family: montserrat;
  /* text-align: left; */
  font-size: 1.2em;
  font-weight: 800;
  font-style: italic;
  -webkit-font-smoothing: subpixel-antialiased;
  /* background-color: blue; */
`

const Emdr = styled.div`
  display: inline-block;
  position: relative;
  font-family: montserrat;
  width: auto;
  font-size: 1.2em;
  font-weight: 800;
  color: white;
  margin-left: 2px;
  padding: 5px 4px 5px 4px;
  border-radius: 3px;
  transform: translateY(0px);
`

export default Logo
