import styled from "styled-components"
import { between } from "polished"
import { motion } from "framer-motion"

export const Content = styled.main`
  width: 80%;
  max-width: 800px;
  padding-top: 20vh;
  padding-bottom: 10vh;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`

export const Screen = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
`

export const HeaderContainer = styled(motion.div)`
  position: absolute;
  /* z-index: 3000; */
  top: 0px;
  left: 0;
  width: 100vw;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Paragraph = styled.p`
  font-family: montserrat;
  font-size: 14px;
  line-height: 1.6em;
  font-weight: 550;
  user-select: none;
`
export const LinkText = styled.span`
  font-family: montserrat;
  color: #123a82;
  font-weight: 800;
  cursor: pointer;
  text-decoration: none;
`

export const Button1 = styled.button`
  font-family: montserrat;
  font-size: ${between("12px", "22px", "250px", "3840px")};
  cursor: pointer;
  font-weight: 500;
  color: white;
  margin-top: 0vh;
  margin-left: 0.25vw;
  padding: 18px 35px 18px 35px;
  background-color: #6e31a0;
  border: none;
  outline: none;
  border-radius: 15px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
`

export const ModalButton = styled(motion.button)`
  flex: 1 1 0;
  margin: 0 5px 0 5px;
  font-family: montserrat;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.6em;
  color: white;
  margin-top: 1vh;
  padding: 10px 10px 10px 10px;
  background-color: #7d4ca2;
  border: none;
  outline: none;
  border-radius: 6px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4), 1px 1px 3px rgba(0, 0, 0, 0.4);
`

export const FormLabel = styled.div`
  font-weight: 600;
  font-size: 0.9em;
  color: #ffe74c;
`
export const FormInput = styled.input`
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
  padding: 15px;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  font-size: 15px;
  background-color: #2196f3;
`
export const FormMessage = styled.textarea`
  resize: none;
  height: ${between("75px", "120px", "250px", "3840px")};
  width: 100%;
  border: 0;
  padding: 15px;
  line-height: ${between("18px", "23px", "250px", "1920px")};
  border-radius: 8px;
  color: white;
  background-color: #2196f3;
  border: none;
  outline: none;
`
