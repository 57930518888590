import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { isMobile } from "react-device-detect"
import {
  indexScreenUrls,
  indexScreenNames,
  footer_listFontSize,
  footer_listLineHeight,
  footer_listFontSize_mobile,
  footer_listLineHeight_mobile,
} from "_src/assets/data/global_variables"

const Pages = (props) => {
  return (
    <>
      <a href="/" style={{ textDecoration: "none" }}>
        <PageLink
          isMobile={isMobile}
          style={{
            color: props.isHamburger ? "white" : "white",
          }}
        >
          Home
        </PageLink>
      </a>
      {indexScreenNames.map((name, i) => (
        <div key={i}>
          {i > 0 && (
            <Link
              style={{ textDecoration: "none" }}
              to={indexScreenUrls[i]}
              state={{
                showFooter: true,
                showNavInstructions: false,
                thisPage: "fromMenu",
                allowNav: false,
              }}
            >
              <PageLink
                isMobile={isMobile}
                style={{
                  marginLeft: "10px",
                }}
              >
                {indexScreenNames[i]}
              </PageLink>
            </Link>
          )}
        </div>
      ))}
    </>
  )
}

const PageLink = styled.div`
  font-family: montserrat;
  font-size: ${footer_listFontSize};
  line-height: ${footer_listLineHeight};
  font-weight: 600;
  color: white;
  cursor: pointer;
  ${({ isMobile }) =>
    isMobile &&
    `
    font-size: ${footer_listFontSize_mobile};
    line-height: ${footer_listLineHeight_mobile}
    `}
`

/* @media only screen and (min-width: 401px) {
    ${({ isMobile }) =>
    isMobile &&
    `
    font-size: ${footer_listFontSize_mobile};
    line-height: ${footer_listLineHeight_mobile}
  `}
  }

  @media only screen and (max-width: 400px) {
    ${({ isMobile }) =>
    isMobile &&
    `
    font-size: 15px;
    line-height: 28px;
  `}
  } */

export default Pages
