import React from "react"
import styled from "styled-components"
import Identify from "./identify"
import Pages from "./navSection1"
import Pages2 from "./navSection2"
import Pages3 from "./navSection3"
import { motion } from "framer-motion"
import { color_footerBg } from "_src/assets/data/global_colors"

const Footer = (props) => {
  return (
    <Content
      isHamburger={props.isHamburger}
      style={{
        backgroundColor: props.isHamburger ? "transparent" : color_footerBg,
        paddingBottom: !props.isHamburger ? "200px" : "50px",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0, duration: 1, ease: "easeInOut" }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0, duration: 2, ease: "easeInOut" }}
      >
        <Inset isHamburger={props.isHamburger}>
          <Row isHamburger={props.isHamburger}>
            <Column1 isHamburger={props.isHamburger}>
              <Identify isHamburger={props.isHamburger} />
            </Column1>
            <Column2>
              <Pages isHamburger={props.isHamburger} />
            </Column2>
            <Column2>
              <Pages2 />
            </Column2>
            <Column2>
              <Pages3 />
            </Column2>
          </Row>
        </Inset>
      </motion.div>
    </Content>
  )
}

const Content = styled(motion.nav)`
  width: 100%;
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  @media only screen and (max-width: 1100px) {
    ${({ isHamburger }) =>
      !isHamburger &&
      `
    justify-content: left;
  `}
  }
`
const Inset = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  ${({ isHamburger }) =>
    isHamburger &&
    `
    flex-direction: column;
  `}
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
`
const Column1 = styled.div`
  ${({ isHamburger }) =>
    !isHamburger &&
    `
  margin: 20px;
  `}
`
const Column2 = styled.div`
  margin: 20px;
`

export default Footer
