import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { isMobile } from "react-device-detect"
import {
  footer_listFontSize,
  footer_listLineHeight,
  footer_listFontSize_mobile,
  footer_listLineHeight_mobile,
} from "_src/assets/data/global_variables"


const Pages = () => {
  return (
    <>
      <Link style={{ textDecoration: "none" }} to="/therapist">
        <PageLink isMobile={isMobile}>Therapist Login</PageLink>
      </Link>
      <Link style={{ textDecoration: "none" }} to="/client">
        <PageLink isMobile={isMobile}>Client Login</PageLink>
      </Link>
      <Link style={{ textDecoration: "none" }} to="/faq">
        <PageLink isMobile={isMobile}>FAQ</PageLink>
      </Link>
    </>
  )
}

const PageLink = styled.div`
  font-family: montserrat;
  font-size: ${footer_listFontSize};
  line-height: ${footer_listLineHeight};
  font-weight: 600;
  color: white;
  cursor: pointer;
  ${({ isMobile }) =>
    isMobile &&
    `
    font-size: ${footer_listFontSize_mobile};
    line-height: ${footer_listLineHeight_mobile}
    `}
`


  /* @media only screen and (min-width: 401px) {
    ${({ isMobile }) =>
      isMobile &&
      `
    font-size: ${footer_listFontSize_mobile};
    line-height: ${footer_listLineHeight_mobile}
  `}
  }

  @media only screen and (max-width: 400px) {
    ${({ isMobile }) =>
      isMobile &&
      `
    font-size: 15px;
    line-height: 28px;
  `}
  }
` */

export default Pages
