import React from "react"
import styled from "styled-components"
import Socials from "./navSocials"
import { isMobile } from "react-device-detect"
import {
  footer_aboutFontSize,
  footer_aboutLineHeight,
  footer_aboutFontSize_mobile,
  footer_aboutLineHeight_mobile,
} from "_src/assets/data/global_variables"

const thisFontSize = isMobile
  ? footer_aboutFontSize_mobile
  : footer_aboutFontSize
const thisLineHeight = isMobile
  ? footer_aboutLineHeight_mobile
  : footer_aboutLineHeight

const Identity = (props) => {
  return (
    <>
      {!props.isHamburger && (
        <a href="/" style={{ textDecoration: "none" }}>
          <SimpleLogo style={{}}>
            <span style={{ color: props.isHamburger ? "#083d77" : "#bdbdbd" }}>
              active
            </span>
            EMDR
          </SimpleLogo>
        </a>
      )}
      {!props.isHamburger && (
        <>
          <Paragraph>
            We provide therapists with easy-to-use mobile and desktop tools to
            reliably offer Bilateral / Dual Attention Stimulation to their
            in-person or remote EMDR clients.
          </Paragraph>
          <Socials />
        </>
      )}
    </>
  )
}

const SimpleLogo = styled.div`
  display: inline-block;
  position: relative;
  z-index: 2;
  font-family: montserrat;
  width: auto;
  font-size: 1.5em;
  font-weight: 800;
  color: #f3f3f3;
  color: white;
  span {
    font-style: italic;
    font-size: 0.75em;
    font-size: calc(${(props) => props.size} * 0.275);
  }
`

const Paragraph = styled.div`
  margin: 5px 0 5px 0;
  max-width: 350px;
  font-size: ${thisFontSize};
  line-height: ${thisLineHeight};
  color: white;
  font-family: montserrat;
  font-weight: 500;
`
export default Identity
