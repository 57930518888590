import React from "react"
import { color_primaryComplimentary } from "_src/assets/data/global_colors"
import { FaBars } from "react-icons/fa"
// import { Logger } from "_data/global_functions.js"
import { motion } from "framer-motion"
import styled from "styled-components"

const Hamburger = (props) => {
  return (
    <Burga
      transition={{
        type: "spring",
        stiffness: 300,
        damping: 30,
        mass: 2,
        restDelta: 0.001,
        restSpeed: 0.0001,
      }}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={() => {
        props.setMenuOpen(true)
      }}
    >
      <FaBars />
    </Burga>
  )
}

const Burga = styled(motion.div)`
  position: relative;
  color: ${color_primaryComplimentary};
  z-index: 1010;
  font-size: 1.75em;
  cursor: pointer;
`

export default Hamburger
