import React from "react"
import Button from "_components/buttons/button_parallax"
import MenuItem from "_components/buttons/button_underline"
import styled from "styled-components"
import {
  color_menuText,
  color_primButton,
} from "_src/assets/data/global_colors"

const FullMenu = () => {
  const pageNames = [
    "Therapist",
    "Client",
    "FAQ",
    // "Pricing",
    // "Roadmap",
    "Contact",
    // "Sign-up",
  ]

  const pageLinks = [
    "/therapist",
    "/client",
    "/faq",
    // "/pricing",
    // "/changelog",
    "/contact",
    // "/signup",
  ]

  return (
    <NavWrapper>
      {pageNames.map((name, i) => (
        <div key={i} style={{ marginLeft: "20px" }}>
          <MenuItem
            copy={name}
            textColor={color_menuText}
            underlineColor={color_primButton}
            destination={pageLinks[i]}
            fontAdjust={0.2}
          />
        </div>
      ))}
      <div
        style={{ marginLeft: "30px", transform: "scale(.8) translateY(-2px)" }}
      >
        <Button
          color={color_primButton}
          shadowColor="black"
          copy="Enter Session"
          destination="/session"
          height={60}
          width={170}
        />
      </div>
      <MenuItem
        copy="Sign-up"
        textColor={color_menuText}
        underlineColor={color_primButton}
        destination="/signup"
        fontAdjust={0.2}
      />
    </NavWrapper>
  )
}

const NavWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: grey; */
  margin: 0;
  padding: 0;
`

export default FullMenu
