export const indexScreenNames = [
  "Home",
  "Top Ten Reasons to Use Our Tools",
  "Built-in Video Conferencing",
  "Get started in 3-Steps",
  "The Past, Present and Future",
]

export const nextScreenLabels_mobile = [
  "Swipe up for the top 10 reasons...",
  "Swipe to learn about built-in video...",
  "Swipe to get started in 3-steps.",
  "The past, present, and future.",
  "",
]

export const nextScreenLabels = [
  "Next up: The top 10 reasons...",
  "Next up: Built-in conferencing.",
  "Next: Get started in 3-steps.",
  "Next: The past, present, and future.",
  "",
]

export const indexScreenUrls = [
  "/",
  "/intro/bls-tool-features/",
  "/intro/built-in-conferencing/",
  "/intro/steps-to-getting-started/",
  "/intro/past-present-future/",
]

export const footer_listFontSize = "13px"
export const footer_listLineHeight = "22px"
export const footer_aboutFontSize = "13px"
export const footer_aboutLineHeight = "22px"
export const footer_listFontSize_mobile = "15px"
export const footer_listLineHeight_mobile = "32px"
export const footer_aboutFontSize_mobile = "15px"
export const footer_aboutLineHeight_mobile = "25px"
