import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { motion, useSpring } from "framer-motion"

function Button(props) {
  const sx = useSpring(0, { stiffness: 500, damping: 60, mass: 1 })
  const scale = useSpring(1.02, { stiffness: 300, damping: 60, mass: 10 })

  function Button_onClick() {
    navigate(props.destination)
  }

  function Button_hover() {
    sx.set(1)
    scale.set(1.1)
  }

  function Button_leave() {
    sx.set(0)
    scale.set(1.02)
  }

  return (
    <motion.div
      style={{
        position: "relative",
        perspective: "500px",
      }}
    >
      <ContainerWrapper
        style={{  scale: scale, perspective: "500px" }}
        whileTap={{ scale: 0.99 }}
      >
        <CopyWrapper
          color={props.textColor}
          onClick={Button_onClick}
          onMouseOver={Button_hover}
          onMouseLeave={Button_leave}
          fontAdjust={props.fontAdjust}
        >
          {props.copy}
        </CopyWrapper>
        <UnderLine
          style={{ scaleX: sx }}
          underlineColor={props.underlineColor}
        />
      </ContainerWrapper>
    </motion.div>
  )
}

const ContainerWrapper = styled(motion.div)``

const CopyWrapper = styled.div`
  font-family: montserrat;
  line-height: calc(1.4em - ${(props) => props.fontAdjust}em);
  margin-top: 2px;
  cursor: pointer;
  font-weight: bold;
  color: ${(props) => props.color};
  font-size: 15px;
`

const UnderLine = styled(motion.div)`
  width: 100%;
  height: 4px;
  background-color: ${(props) => props.underlineColor};
`

export default Button
