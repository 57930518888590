export const logoBlue = "#083d77"
export const hiddenMenu = "#673ab7"
export const primaryButton = "#6e31a0"
export const secondaryButton = "#d6c241"

export const color_primary = "#2196f3"
export const color_primaryComplimentary = "#00367d"
export const color_secondary = "#ae54d5"

export const color_ball = "#4387bd"
export const color_footerBg = "#225ead" //"#224e8a" //"#083D77"
export const color_footerLogoColor = "#BDBDBD"
export const color_footerText = "white"
export const color_headerBackground = "white" //"#fbc259"
export const color_logoActive = "#174684" 
export const color_logoBlock = "#3196e6"
export const color_logoColor = "#225ead"
export const color_logoEmdr = "#3f51b5" 
export const color_logoWhite = "#f3f3f3"
export const color_menu = "#3196e6"
export const color_menuDots = 'orange'
export const color_menuText = '#174684'
export const color_nextPageArrow = "#ae54d5"
export const color_nextPageText = "#174684"
export const color_pageBg = "#ffffff"
export const color_pageSubTitle = "#b25c3d"
export const color_formInput = "#2196f3"
// export const color_formInputSelected = "#2384d2"
export const color_formLabel = '#00367d'
export const color_pageTitle = "#2196f3" //"#2196f3", 225EAD
export const color_paragraph = "#0c2940"
export const color_paragraphHighlite = '#004aad'
// export const color_primButton = "#ae54d5"
export const color_primButton = "#ae57d2"
export const color_textLink = "#004aad"
export const color_subHeading = "#004aad"
export const color_youtubeRed = "#e91d00"