import React, { useRef } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { motion, useAnimation } from "framer-motion"

const spring = {
  type: "spring",
  stiffness: 530,
  damping: 60,
  mass: 6,
  restDelta: 0.001,
  restSpeed: 0.001,
}

function Button2(props) {
  const anim = useAnimation()
  const ref = useRef()
  const buttonHeight = useRef(props.height)
  const buttonWidth = useRef(props.width)

  return (
    <div
      style={{
        perspective: "500px",
      }}
    >
      <motion.div
        style={{
          borderRadius: "30px",
          width: buttonWidth.current,
          height: buttonHeight.current,
        }}
        ref={ref}
        transition={spring}
        animate={anim}
        initial={{
          boxShadow:
            "1px 1px 5px rgba(0, 0, 0, 0.2), 0px 0px 6px 5px rgba(230,28,230,0.075)",
        }}
        whileHover={{
          scale: 1.05,
          boxShadow:
            "1px 1px 15px rgba(0, 0, 0, 0.125), 0px 0px 15px 10px rgba(230,28,230,0.1)",
        }}
        whileTap={{ scale: 0.99, buttonStatus: 1 }}
        onMouseMove={function (e) {
          const { clientX, clientY } = e
          const offsetXFromCenter =
            clientX -
            buttonWidth.current / 2 -
            ref.current.getBoundingClientRect().left
          const offsetYFromCenter =
            clientY -
            buttonHeight.current / 2 -
            ref.current.getBoundingClientRect().top
          anim.start({
            rotateX: props.noParallax ? 0 : -offsetYFromCenter / 10,
            rotateY: props.noParallax ? 0 : offsetXFromCenter / 10,
          })
        }}
        onMouseLeave={function () {
          anim.start({
            rotateX: 0,
            rotateY: 0,
          })
        }}
      >
        <ButtonWrapper
          onClick={() => {
            navigate(props.destination)
          }}
          color={props.color}
        >
          <div style={{ transform: `scale(${props.fontScale})` }}>
            {props.copy}
          </div>
        </ButtonWrapper>
      </motion.div>
    </div>
  )
}

const ButtonWrapper = styled.button`
  font-family: montserrat;
  /* font-size: 1em; */
  font-size: 17px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  color: white;
  color: ${(props) => props.textColor};
  background-color: #6e31a0;
  background-color: ${(props) => props.color};
  border: none;
  outline: none;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
`

export default Button2
