import React from "react"
import { color_secondary } from "_data/global_colors"
import { motion } from "framer-motion"
import styled from "styled-components"

const Close = (props) => {
  return (
    <OnHover
      style={{
        perspective: "500px",
      }}
      transition={{
        type: "spring",
        stiffness: 300,
        damping: 20,
        mass: 2,
        restDelta: 0.001,
        restSpeed: 0.0001,
      }}
      onClick={() => {
        props.setMenuOpen(false)
      }}
      initial={{ rotate: 0 }}
      whileHover={{
        scale: 1.1,
      }}
    >
      <Icon>X</Icon>
    </OnHover>
  )
}

const OnHover = styled(motion.div)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 200;
  border-radius: 100%;
  box-shadow: 2px 2px 8px 3px rgba(66, 34, 62, 0.16);
  cursor: pointer;
  background-color: ${color_secondary};
`

const Icon = styled(motion.div)`
  color: white;
  user-select: none;
  font-family: montserrat;
  font-weight: 700;
  font-size: 20px;
`

export default Close
